import React from "react"
import { PageWrapper } from "~components"
import HeaderButton from '~sections/project/Header'
//import { Link } from "gatsby";
import CtaSection from "~sections/marketing/Cta"
import FooterOne from "~sections/marketing/FooterOne"
import BreadCrumbSection from "~sections/about/BreadCrumb";
import ServicesSection from "~sections/agency/Services/ServicesSection"
import Testimonial from "~sections/customer-stories/Testimonial"
import { Helmet } from "react-helmet";
//import scrollTo from 'gatsby-plugin-smoothscroll';
const header = {
  headerClasses: "site-header site-header--menu-start light-header site-header--sticky",
  containerFluid:false,
  buttonBlock: (
    <HeaderButton
      className="ms-auto d-none d-xs-inline-flex"
      btnOneText="Login"
      btnTwoText="14 day free trial"
      mr="15px"
      mrLG="0"
    />
  ),
}

export default function Agency() {
  return (
    <>
    <Helmet>
        <title>We-Connect | Grow Your Agency</title>
        <meta name="description" content="Learn how to scale your agency using We-Connect, the safest LinkedIn outreach tool. Learn why We-Connect is trusted by hundreds of B2B agencies worldwide."/>
        <meta property="og:title" content="We-Connect | Grow Your Agency "/>
        <meta property="og:description" content="Learn how to scale your agency using We-Connect, the safest LinkedIn outreach tool. Learn why We-Connect is trusted by hundreds of B2B agencies worldwide."/>
        <meta property="og:image" content="https://we-connect.io/hwe-connect_agency-page.png"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://we-connect.io/agency"/>
        <meta property="twitter:card" content="summary_large_image"/><meta property="twitter:site" content="@WeConnect_io"/>
        <meta property="twitter:description" content="Learn how to scale your agency using We-Connect, the safest LinkedIn outreach tool. Learn why We-Connect is trusted by hundreds of B2B agencies worldwide."/>
        <meta property="twitter:title" content="We-Connect | Grow Your Agency"/>
        <meta property="twitter:image" content="https://we-connect.io/we-connect_agency-page.png"/>
    </Helmet>
    <PageWrapper headerConfig={header}>
      <BreadCrumbSection />
      <ServicesSection />
      <Testimonial />
      <CtaSection />
      <FooterOne/>
    </PageWrapper>
    </>
  )
}
