import React from 'react'
import BreadCrumb from './style'
import { StaticImage as Img } from 'gatsby-plugin-image'
import { Col, Row, Container  } from 'react-bootstrap'
import { SuperTag } from '~components'
import img from '../../../assets/image/Agency.svg'
import { Link } from 'gatsby'
export default function BreadCrumbSection({backgroundColor,colClasses,...rest}){
return(
<BreadCrumb backgroundColor="#f3f4f6" {...rest}>
  <Container>
    <Row className="row justify-content-center text-center">
      <Col className={colClasses ? colClasses : "col-xl-8"}>
        <BreadCrumb.Title as="h2">Grow your agency with the most powerful LinkedIn automation software.</BreadCrumb.Title>
        <BreadCrumb.Image>
        <img className="w-100" src={img} alt="content" layout="fullWidth" placeholder="blurred" />
        </BreadCrumb.Image>
        <Link target="_blank"  to="//account.we-connect.io/signup"><BreadCrumb.Button textColor="#fff" hoverTextColor="#fff" backgroundColor={backgroundColor}  hoverBackgroundColor={backgroundColor} rounded={true} sizeX="180px" sizeY="56px">Try it now</BreadCrumb.Button></Link>
      </Col>
      <p>Generate 10X leads for your clients | Manage more clients with fewer resources | Simplify the way you do business.</p>
    </Row>
  </Container>
  
</BreadCrumb>
)
}