import styled from 'styled-components/macro';
import { Box, Heading,Button,Paragraph } from '~styled';

const Card = styled(Box)`
    border-radius: 15px;
    padding-left: 25px;
    padding-right: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
    @media (min-width:575px){
        display:flex;
        align-items:center;
    }
    @media (min-width:768px) {
      display:flex;
        align-items:center;
    }
`
Card.Title = styled(Heading)`
      font-size: 21px;
      font-weight: 500;
      font-style: normal;
      letter-spacing: normal;
      line-height: 30px;
`
Card.Text = styled(Paragraph)`
      margin-bottom: 0;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: normal;
      line-height: 32px;
      opacity:.7;
`
Card.Body = styled(Box)``
Card.Icon = styled(Box)`
    
    min-width: 65px;
    min-height: 65px;
    max-width: 65px;
    max-height: 65px;
    border-radius: 50%;
    position: relative;
    margin-bottom: 65px;
    margin-right: 30px;
    img {
        position: absolute;
        bottom: -18%;
        right: 10%;
        height: auto;
        max-width: 100%;
      }
`

export default Card;