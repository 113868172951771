import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import ServiceCard from './Components/Card'
import Service from "./style"
import {Link} from 'gatsby'
import productivity from "../../../assets/image/marketing/Increase-Productivity.svg";
import time from "../../../assets/image/marketing/Simplify-and-Save-Time.svg";
import secure from "../../../assets/image/marketing/Confidetiality.svg";
export default function ServicesSection(){
return(
<Service>
  <Container>
    <Row className="row justify-content-center text-center">
      <Col >
          
          <Service.Title as="h3"  mb="40px">There is a reason why b2b lead generation agencies are rapidly adopting We-Connect. It’s the best solution designed and priced for agencies.</Service.Title>
          
          <Link target="_blank"  to="//account.we-connect.io/signup"><Service.Button textColor="#fff" hoverTextColor="#fff" sizeX="180px" sizeY="56px" rounded="true">Try 14 days for free</Service.Button></Link>
          <Service.Subtitle as="h5" mb="22px">Agency plan is not available for free trial but you can try the individual plan free.</Service.Subtitle>
      </Col>
      </Row>
      <Service.Box mtLG="100px" mtMD="40px">
      <Row className="justify-content-center justify-content-md-start">
      <Col className="col-lg-4 col-xs-6 col-10">
        
            
            <ServiceCard image={productivity} title="Increase Productivity" text="Manage multiple clients from one single dashboard.

              Switch from one client to another with one click within the platform.

              Run simultaneous campaigns for as many clients.

              Advanced message and reply management console.

              No need to stay glued to your computer. Campaigns keep running when you are away or signed out." mb="25px"/>
      </Col>
      <Col className="col-lg-4 col-xs-6 col-10">
        
            
            <ServiceCard image={time} title="Simplify and save time" text="Check messages and reply directly from we-connect. See replies from each campaign.

Never miss a lead. Mark messages as leads, unread, follow-up, and archive in We-connect.

Simplify your inbox. Sort messages as leads, unread, follow-up, and archive in We-connect.

Save multiple notes for each contact like in a CRM.

Group and segment your contacts. Create and save multiple tags for each contact. Search contacts by tags." mb="25px"/>
      </Col>
      <Col className="col-lg-4 col-xs-6 col-10">
        
            
            <ServiceCard image={secure} title="Confidentiality &amp; Security" text="Complete client confidentiality. Your clients will never hear from us.

Only the Admin gets we-connect notifications.

Customizable notification management. Get notified of only what is important to you.

Supervise accounts & campaigns managed by staff.

Dynamic IP addresses allocation. Minimize log-ins to client’s LinkedIn accounts." mb="25px"/>
      </Col>
      </Row>
      </Service.Box>
  </Container>
</Service>

)
}