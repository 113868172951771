import styled from 'styled-components/macro';
import { Box, Heading,Button,Paragraph } from '~styled';
import {transparentize} from "polished";
const propToColor = (arg = "#442cd6" ) => transparentize(0.70, arg);
const Service = styled(Box)`
padding-top: 55px;
    padding-bottom: 36px;

    @media (min-width:768px) {
        padding-top: 75px;
        padding-bottom: 55px;
    }

    @media (min-width:992px) {
        padding-top: 130px;
        padding-bottom: 105px;
    }
`
Service.Title = styled(Heading)`
    font-size: 18px;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 1.3;
    text-align: center;
    @media (min-width:768px) {
      font-size: 16px;
    }

    @media (min-width:992px) {
      font-size: 18px;
    }
`
Service.Subtitle = styled(Paragraph)`
    font-size: 12px;
    font-weight: 100;
    letter-spacing: normal;
    line-height: 1.75;
    text-align: center;
`
Service.Text = styled(Paragraph)`
    font-size: 18px;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 1.77777;
`
Service.Button = styled(Button)`
transition:.4s;
    margin-bottom: 40px;
    border-color:${props => props.backgroundColor? props.backgroundColor: "#5034fc" };
    background:${props => props.backgroundColor? props.backgroundColor: "#5338FC" };
    box-shadow:  ${props => "0 4px 4px " + propToColor(props.backgroundColor)};
  &:hover{
    transform: translateY(-5px);
    border-color:${props => props.hoverBackgroundColor? props.hoverBackgroundColor: "#442cd6" };
    background:${props => props.hoverBackgroundColor? props.hoverBackgroundColor: "#442cd6" };
    box-shadow:  ${props => "0 4px 4px " + propToColor(props.hoverBackgroundColor)};
  }
`
Service.Box = styled(Box)``
export default Service;